<template>
    <!-- 聯絡我們 -->
    <div class="top_wrapper">
        <div class="page_title">聯絡我們</div>
        <img class="banner" src="@/assets/banner/bn-contact.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section">
        <div>IG：<a href="https://www.instagram.com/warm.2021" target="_blank">warm.2021</a></div>
        <div>FB：<a href="https://www.facebook.com/warm.2021" target="_blank">台灣有點溫暖毛孩協會</a></div>
        <div>官方信箱：<a href="mailto:warmdog2021@gamil.com" target="_blank">warmdog2021@gamil.com</a></div>
        <div>官方LINE：@warm.tw<a href="https://lin.ee/zbqYaz6" target="_blank">(點我加入好友)</a></div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0 24px 16px 24px;
    font-size: 15px;
    gap: 6px;
}

.text_section a {
    text-decoration: underline;
    color: #809da5;
}
</style>
