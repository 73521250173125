import axios from "axios";

const commonRequest = axios.create({
    baseURL: "/api",
    // baseURL: "https://www.warmwarm.tw/api/",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*", // Add this line to allow all origins (adjust as needed)
    },
    withCredentials: true, // Ensure this is set if you are using credentials
});

const jsonRequest = axios.create({
    baseURL: "/api",
    // baseURL: "https://www.warmwarm.tw/api/",
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*", // Add this line to allow all origins (adjust as needed)
    },
    withCredentials: true, // Ensure this is set if you are using credentials
});

// Add a request interceptor
commonRequest.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("wwt");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
commonRequest.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

// Add a request interceptor
jsonRequest.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("wwt");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
jsonRequest.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

//個人檔案
export const getUserSelf = () => commonRequest.get("/user/self/");
//線上購物
export const getShop = (page = 1, limit = 1) =>
    commonRequest.get(`/shop/?page=${page}&limit=${limit}`);
//線上捐糧
export const getDonateFood = () => commonRequest.get("/donation/food/");
//線上捐物資
export const getDonateMaterial = () => commonRequest.get("/donation/material/");

//首頁-影片(單 1 則 後台可新增/編輯)
export const getHomeVideo = (page = 1, limit = 1) =>
    commonRequest.get(`/home_video/?${page}&limit=${limit}`);
//常見問題
export const getFaqList = (page = 1, limit = 1) =>
    commonRequest.get(`/qa/?${page}&limit=${limit}`);
//年度報告
export const getYearlyReport = (page = 1, limit = 1) =>
    commonRequest.get(`/yearly_report/?${page}&limit=${limit}`);

//會員捐款紀錄
export const getDonationRecords = () =>
    commonRequest.get("/user/donation_records/");

//會員資訊個人檔案修改
export const patchUserId = ({ id, body }) => {
    jsonRequest.patch(`/user/${id}/`, body);
};
