<template>
    <!-- 郵政劃撥 -->
    <div class="title">郵政劃撥</div>
    <div class="text">
        <div class="info-title">捐款說明</div>
        <div class="section">
            <div>請您至郵局所以劃撥單或下載忘錄劃撥單(PDF)</div>
            <div>填寫以下資訊</div>
            <div>郵政劃撥帳號:50447671</div>
            <div>戶名:社團法人台灣有點暖毛孩協會</div>
        </div>
        <div class="section">請詳細填寫捐款人聯絡資料，至郵局窗口完成劃撥程序即可</div>
        <div class="section">
            <div>如有問題請聯繫我們</div>
            <div>官方信箱：<a href="mailto:warmdog2021@gamil.com" target="_blank">warmdog2021@gamil.com</a></div>
            <div>官方LINE：@warm.tw<a href="https://lin.ee/zbqYaz6" target="_blank">(點我加入好友)</a></div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
    font-size: 18px;
    font-weight: 700;
    padding: 24px;
    padding-bottom: 12px;
}

.info-title {
    font-weight: 700;
    margin-bottom: 16px;
    border-top: 1px solid #d2d2d2;
    padding-top: 16px;
}

.text {
    padding: 24px;
    padding-top: 0;
}

.section {
    margin-bottom: 16px;
    font-size: 14px;
}

.section a {
    text-decoration: underline;
    color: #809da5;
}
</style>
