<template>
    <div class="login_section">
        <div class="normal_login">
            <div class="normal_title">會員登入</div>
            <form @submit.prevent="login">
                <div class="normal_input">
                    <div class="inputs">
                        <div>E-mail</div>
                        <input type="email" v-model="body.username" required />
                    </div>
                    <div class="inputs">
                        <div>密碼</div>
                        <input type="password" v-model="body.password" required />
                        <div class="forget">
                            <router-link :to="{ name: 'ForgotPassword' }"> 忘記密碼 </router-link>
                        </div>
                    </div>
                </div>
                <div class="login">
                    <div type="submit" @click="handleLogin()">會員登入</div>
                </div>
            </form>
            <div class="social">
                <img src="@/assets/login/google.png" alt="" />
                <img src="@/assets/login/line.png" alt="" />
            </div>
            <div class="or">
                <div>或</div>
            </div>
            <div class="register">
                <router-link :to="{ name: 'RegisterAccount' }"> 註冊會員 </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const body = reactive({
            username: "c24312265@gmail.com",
            password: "000000",
        });

        const handleLogin = () => {
            store
                .dispatch("handleLogin", body)
                .then(() => {
                    router.push({ name: "Home" });
                })
                .catch((error) => {
                    console.error("handleLogin failed:", error);
                });
        };
        return {
            body,
            handleLogin,
        };
    },
};
</script>

<style>
.login_section {
    display: flex;
    justify-content: center;
    padding: 36px 64px;
}

.normal_login {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.normal_title {
    text-align: center;
}

.normal_input {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputs {
    width: 100%;
}

.inputs input {
    width: calc(100% - 16px);
    border-radius: 6px;
    border: 1px solid rgb(183, 171, 161);
    padding: 8px;
}

.forget {
    font-size: 12px;
    padding: 6px 0;
    display: flex;
    justify-content: flex-end;
}

.login {
    text-align: center;
}

.social {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.social img {
    margin: 0 10px;
    width: 100%;
    max-width: 250px;
}

.or {
    text-align: center;
    color: gray;
}

.register {
    width: 100%;
    text-align: center;
}

.login div,
.register {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid grey;
    padding: 6px 0px;
}

a {
    text-decoration: none;
    color: #333333;
}
</style>
