<template>
    <!-- 線上刷卡 -->
    <div class="title">線上刷卡-單筆捐款</div>
    <div class="process">
        <img src="@/assets/process.png" alt="" />
    </div>
    <form class="donate-info" @submit.prevent="validateForm">
        <div class="section">
            <div class="title">捐款金額</div>
            <div class="money-input">
                <div class="input-container">
                    <input type="text" v-model="formattedNumber" @input="formatNumber" class="styled-input" placeholder="請輸入金額，金額需為台幣100元以上" />
                </div>
                <div>元</div>
            </div>
        </div>
        <div class="section">
            <div class="title">捐款人資料</div>
            <div class="hint">(若你不願提供個資，請選擇不具名捐款，<span class="highlight">將無法索取收據</span>)</div>
            <div class="donate-type">
                <div class="anonymous">
                    <router-link :to="{ name: 'ATMTransfer' }"> 不具名捐款 </router-link>
                </div>
                <div class="member">
                    <router-link :to="{ name: 'RegisterAccount' }"> 註冊會員 </router-link>
                    <router-link :to="{ name: 'LogIn' }"> 會員登入 </router-link>
                </div>
            </div>
            <div class="field-title">
                <div class="required-field">姓名</div>
                <div class="input-container">
                    <input type="text" v-model="field.name" class="styled-input" placeholder="若多名款人請以半形逗點區分" name="name" />
                </div>
            </div>
            <div class="radio-group custom-radios">
                <div class="radio-row">
                    <label>
                        <input type="radio" value="君" v-model="field.prefix" />
                        君
                    </label>
                    <label>
                        <input type="radio" value="公司" v-model="field.prefix" />
                        公司
                    </label>
                    <label>
                        <input type="radio" value="先生" v-model="field.prefix" />
                        先生
                    </label>
                </div>
                <div class="radio-row">
                    <label>
                        <input type="radio" value="小姐" v-model="field.prefix" />
                        小姐
                    </label>
                    <label>
                        <input type="radio" value="伉儷" v-model="field.prefix" />
                        伉儷
                    </label>
                    <label>
                        <input type="radio" value="女士" v-model="field.prefix" />
                        女士
                    </label>
                </div>
            </div>
            <div class="field-title">
                <div class="required-field">手機</div>
                <div class="input-container">
                    <input type="text" v-model="field.phone" class="styled-input" placeholder="請輸入手機" name="phone" />
                </div>
            </div>
            <div class="field-title">
                <div>身分證字號/居留證號</div>
                <div class="input-container">
                    <input type="text" v-model="field.ID" class="styled-input" placeholder="請輸入身分證字號/居留證號" name="ID" />
                </div>
            </div>
            <div class="field-title">
                <div class="required-field">電子郵件</div>
                <div class="input-container">
                    <input type="text" v-model="field.email" class="styled-input" placeholder="請輸入電子郵件" name="email" />
                    <span v-if="errors.email">{{ errors.email }}</span>
                </div>
            </div>
            <div class="field-title">
                <div>地址</div>
                <div class="input-container">
                    <input type="text" v-model="field.address" class="styled-input" placeholder="請輸入地址" name="address" />
                </div>
            </div>
        </div>
        <div class="section note">
            <div class="title">收據資料及說明</div>
            <div class="text">發票預設皆為捐贈，如果您需要可抵扣稅額的正式捐款收據，請這「聯絡我們」索取收據。</div>
            <div class="text">請依序提供收據抬頭/身分證字號或統編/電子郵件/手機/收件人資訊，我們將盡快為您寄出。</div>
            <div class="text">捐款前先註冊/登入會員，可於會員專區查詢線上捐款紀錄。</div>
        </div>
        <button type="submit" class="submit">下一步</button>
    </form>
</template>

<script>
import { reactive, ref, watch } from "vue";
export default {
    setup() {
        const formattedNumber = ref("");
        const field = reactive({
            price: "",
            name: "",
            prefix: "公司",
            phone: "",
            ID: "",
            email: "",
            address: "",
        });

        const errors = reactive({});

        watch(
            () => field.price,
            (newValue) => {
                formattedNumber.value = newValue
                    .replace(/\D/g, "") // 移除非数字字符
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 添加逗号
            }
        );

        const formatNumber = (event) => {
            field.price = event.target.value.replace(/,/g, ""); // 移除旧的逗号
        };

        const validateForm = () => {
            let valid = true;
            errors.value = {};

            if (!field.email) {
                errors.value.email = "邮箱是必填项";
                valid = false;
            } else if (!/\S+@\S+\.\S+/.test(field.email)) {
                errors.value.email = "请输入有效的邮箱地址";
                valid = false;
            }

            console.log(errors);

            if (valid) {
                console.log("表单验证通过");
            }
        };

        return {
            formattedNumber,
            formatNumber,
            field,
            errors,
            validateForm,
        };
    },
};
</script>

<style scoped>
.title {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    padding: 24px;
    padding-bottom: 12px;
}

.process {
    padding: 12px;
}

.process img {
    width: 100%;
    height: auto;
}

.donate-info {
    padding: 12px 10%;
    text-align: center;
}

.donate-info .section:not(:last-child) {
    margin-bottom: 16px;
}

.section .title {
    padding-top: 12px !important;
}

.money-input {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.hint {
    font-size: 10px;
    font-weight: 500;
}

.hint .highlight {
    color: orange;
}

.anonymous,
.member {
    display: flex;
    justify-content: center;
    margin: 12px 0;
    gap: 12px;
}

.donate-type a {
    text-decoration: none;
    color: #333333;
    background: #fff;
    padding: 6px;
    border: 1px solid #333333;
    border-radius: 4px;
    width: 100%;
}

.field-title {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 4px;
}

.section .field-title:not(:last-child) {
    margin-bottom: 12px;
}

.note {
    text-align: left;
}

.note .title {
    justify-content: left;
    font-size: 18px;
    font-weight: 700;
    padding-left: 0;
}

.note .text {
    font-size: 7px;
}

.submit {
    border-radius: 4px;
    width: 230px;
    height: 36px;
    background-color: #b7aba1;
    border: 0.3pt solid #bebcbc;
    text-align: center;
    color: #fff;
}
</style>
