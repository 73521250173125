<style scoped></style>
<template>
    <!-- 線上助養detail(inner) -->
    <div class="container">
        <swiper
            :style="{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
            }"
            :loop="true"
            :spaceBetween="10"
            :navigation="true"
            :thumbs="{ swiper: thumbsSwiper }"
            :modules="modules"
            class="mySwiper2"
        >
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
        </swiper>
        <swiper
            @swiper="setThumbsSwiper"
            :loop="true"
            :spaceBetween="10"
            :slidesPerView="3"
            :freeMode="true"
            :watchSlidesProgress="true"
            :modules="modules"
            :cssMode="true"
            class="mySwiper"
        >
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
            <swiper-slide><img src="@/assets/feed02.png" /></swiper-slide>
        </swiper>
    </div>

    <div class="info">
        <div class="details">
            <div class="sub-title">Jeff</div>
            <div class="sub-title sub-text">男生/2歲/新北市</div>
            <div class="sub-title sub-text">未被領養</div>
            <div class="sub-title sub-text">已施打疫苗</div>
        </div>
        <div class="cost">
            每隻浪浪一個月的生活費大約3000元(包含吃/醫療)。 可以申請信用卡固定扣款,成為牠的助養主人。 於結帳畫面選取「定期捐款」,選擇一/二/三/五年。
        </div>
        <div class="button-section">
            <router-link :to="{ name: 'DonateHome' }">我要助養</router-link>
            <router-link :to="{ name: 'ContactUs' }">我要領養</router-link>
        </div>
        <div class="describe">描述</div>
        <div class="text">
            今年一月原本在彰化流浪時就被套上橡皮筋的可憐大麥町,Jeff,愛媽接到彰化志工求助去電想要帶出幫忙醫療,但收容所說脖子的橡皮筋已經取下傷口不深,因為太多人詢問領養因此必須等待12天結紮後”抽籤”,愛媽想既然有這麼多人想照顧當然就不錦上添花。
            定期定額助養主人：目前 10位陳O卉500.一間故事文理短期補習班500.龔O平3000.羅O馨1000(生活費尚缺 0位助養主人)
            *此為志工手動更新,助養後資訊會於1周內更新完成 住院1個月後,目前已帶回狗場安置照顧,仍在吃藥進行焦 蟲療程中! 個性：親人
        </div>
        <div class="video">
            <img class="big" src="@/assets/feed02.png" alt="" />
        </div>
        <div class="button-section">
            <router-link :to="{ name: 'DonateHome' }">我要助養</router-link>
            <router-link :to="{ name: 'ContactUs' }">我要領養</router-link>
        </div>
        <div class="section note">
            <div class="title">領養須知</div>
            <div class="text">
                <div>| 領養人需已年滿二十歲,且同住家人均同意飼養寵物。</div>
                <div>| 開放認養犬貓皆已完成節育、植入晶片及施打預防針,日後由飼主自行定期進行狂犬病疫苗注射、驅蟲及健康檢查。</div>
                <div>| 當犬貓有傷病情形,飼主應給予妥善醫療照護。</div>
                <div>| 務必留下真實聯絡資訊,領養後仍需與志工保持聯繫,並同意不定期家訪或提供犬貓近況照片影片。</div>
                <div>| 如已充份了解以上說明,請下載領養申請書,並詳實填寫資料後,以電子郵件回傳至 warmdog2021@gmail.com ,將由領養部門進行審核及評估。</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const thumbsSwiper = ref(null);

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };

        return {
            thumbsSwiper,
            setThumbsSwiper,
            modules: [FreeMode, Navigation, Thumbs],
        };
    },
};
</script>

<style scoped>
.container,
.info {
    padding: 24px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper {
    margin-top: 24px;
}

.info {
    padding-top: 0;
    text-align: center;
}

.sub-title {
    font-weight: 600;
}

.sub-text {
    font-size: 12px;
}

.details {
    margin-bottom: 12px;
}

.cost {
    font-size: 10px;
    padding: 0 48px;
    margin-bottom: 12px;
    color: #7b8080;
}

.describe {
    font-size: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #7b8080;
    margin-bottom: 12px;
}

.text {
    font-size: 10px;
    margin-bottom: 12px;
}

.video {
    margin-bottom: 12px;
}

.section .field-title:not(:last-child) {
    margin-bottom: 12px;
}

.note {
    text-align: left;
}

.note .title {
    justify-content: left;
    font-size: 18px;
    font-weight: 700;
    padding-left: 0;
}

.note .text {
    font-size: 10px;
}

.button-section {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 12px;
}

.button-section a {
    background-color: #ffffff;
    color: #333333;
    border-radius: 4px;
    border: 1px solid #333333;
    text-decoration: none;
    font-size: 12px;
    padding: 8px 12px;
    width: 100%;
}
</style>
