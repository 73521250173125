<template>
    <!-- 常見問題 -->
    <div class="top_wrapper">
        <div class="page_title">常見問題</div>
        <img class="banner" src="@/assets/banner/bn-qa.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section" v-for="i in items" :key="i.id">
        <div @click="togglenAnswer(i.id)">
            <span class="question">{{ i.title }}</span>
        </div>
        <div class="anwser" v-show="activeId === i.id">
            {{ i.content }}
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();
        const page = ref(1);
        const limit = ref(2);
        const activeId = ref(null);

        const items = ref([]);
        const handleGetFaqList = () => {
            store
                .dispatch("handleGetFaqList", {
                    page: page.value,
                    limit: limit.value,
                })
                .then((res) => {
                    items.value = res;
                })
                .catch((error) => {
                    console.error("handleGetFaqList failed:", error);
                });
        };

        const togglenAnswer = (id) => {
            activeId.value = activeId.value === id ? null : id;
        };

        onMounted(() => {
            handleGetFaqList();
        });

        return {
            items,
            togglenAnswer,
            activeId,
        };
    },
};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    text-align: left;
    padding: 0 24px 16px 24px;
    font-size: 15px;
}

.text_section .question {
    cursor: pointer;
}

.text_section .anwser {
    color: #bcb0a6;
    margin-top: 8px;
}

.text_section .answer {
    display: none;
}
.text_section .answer[style*="display: block"] {
    display: block;
}
</style>
